/* eslint-disable prettier/prettier */
<template>
  <div data-app>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-dialog v-model="dialog" width="700" v-if="brand_asset.id">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="lighten-2" darkP v-bind="attrs" v-on="on">
              NUOVO CAPITOLO
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Nuovo capitolo
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col md="12" xl="8">
                  <v-text-field
                    label="Titolo"
                    v-model="chapter.title"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <b>Descrizione</b><br />
                  <ckeditor
                    :editor="editor"
                    v-model="chapter.description"
                    @ready="onReady"
                  ></ckeditor>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" xl="6">
                  <v-text-field
                    label="Posizione"
                    v-model="chapter.position"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="addChapter()">
                Aggiungi
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="col-md-12 offset-lg-1 col-lg-10 offset-xl-2 col-xl-8">
        <div class="card card-custom card-stretch">
          <div class="card-body">
            <v-tabs v-model="tab" grow>
              <v-tab>Intro</v-tab>
              <v-tab v-for="item in brand_asset.chapters" :key="item.id">
                {{ item.title }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row class="mt-5 wrapper-gallery">
                    <v-col>
                      <div class="gallery" v-if="!logoUrl">
                        <v-row>
                          <v-col>
                            <h5>Logo brand</h5>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <div class="wrapper">
                              <v-icon class="ml-2 mr-2" color="white">
                                fas fa-plus
                              </v-icon>
                              <input
                                type="file"
                                @change="addImage($event)"
                                accept="image/*"
                                name="upload"
                              />
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="img-row" v-else>
                        <v-row>
                          <v-col>
                            <h5>Logo brand</h5>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="4">
                            <div class="wrapper images">
                              <img :src="logoUrl" />
                              <v-icon @click="deleteLogo()" class="cancel-img"
                                >fas fa-times-circle</v-icon
                              >
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12" xl="6">
                      <v-text-field
                        label="Titolo pagina"
                        v-model="brand_asset.title"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="6" xl="6">
                      <v-select
                        :items="headers"
                        label="Header"
                        v-model="brand_asset.page.header"
                      ></v-select>
                    </v-col>
                    <v-col md="6" xl="6">
                      <v-select
                        :items="headers"
                        v-model="brand_asset.page.mode"
                        label="Stile pagina"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <b>Descrizione</b><br />
                      <ckeditor
                        :editor="editor"
                        v-model="brand_asset.description"
                        @ready="onReady"
                      ></ckeditor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex justify-end">
                      <v-btn
                        color="primary"
                        :disabled="!valid"
                        dark
                        class="mb-2"
                        @click="submit"
                        >Salva</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item v-for="item in brand_asset.chapters" :key="item.id">
                <Chapter
                  :chapter="item"
                  @brandAsset="brandAsset"
                  @change="brandAsset"
                  @onChapterDeleted="brandAsset"
                />
              </v-tab-item>
            </v-tabs-items>

            <v-row>
              <v-col cols="12"> </v-col>
            </v-row>
          </div>
          <!--end::Body-->
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/it";
import { mapActions } from "vuex";
import {
  CREATE_BRAND_ASSETS,
  UPDATE_BRAND_ASSETS,
  BRAND_ASSETS_DETAIL,
} from "@/core/services/store/brand_assets.module";
import { CREATE_CHAPTER } from "@/core/services/store/chapters.module";
import { DELETE_ATTACHMENT } from "@/core/services/store/attachments.module";
import Chapter from "../../components/Chapter.vue";

export default {
  components: {
    ckeditor: CKEditor.component,
    Chapter,
  },
  data() {
    return {
      pageTitle: "Pagine",
      valid: false,
      dialog: false,
      brand_asset: {
        page: {},
      },
      headers: ["light", "dark"],
      editor: DecoupledEditor,
      editorConfig: {
        language: "it",
      },
      tab: null,
      items: [],
      chapter: {},
      brandAssetId: this.$route.params.id || "",
      logoUrl: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Pagine",
      },
    ]);
    this.$nextTick(() => {
      this.initialize();
    });
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    initialize() {
      if (this.brandAssetId) {
        this.brandAsset();
      }
    },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    addChapter() {
      this.chapter.brand_asset_id = this.brand_asset.id;
      // DISPATCH
      this.$store
        .dispatch(CREATE_CHAPTER, {
          chapter: this.chapter,
        })
        .then(() => {
          this.showSnack({
            text: "Capitolo creato con successo.",
            color: "success",
            timeout: 3500,
          });
          this.dialog = false;
          this.brandAsset();
        })
        .catch((error) => {
          this.showSnack({
            text: error.message,
            color: "error",
            timeout: 3500,
          });
        });
    },
    submit() {
      // VALIDATE
      this.$refs.form.validate();

      // --
      if (this.valid) {
        let action = this.brandAssetId
          ? UPDATE_BRAND_ASSETS
          : CREATE_BRAND_ASSETS;

        // CLEAN
        let body = Object.assign({}, this.brand_asset);
        body.page = JSON.stringify(body.page);
        delete body.chapters;
        delete body.blocks;

        // CONVERT
        let form = this.jsonToFormData({ brand_asset: body });

        // DISPATCH
        this.$store
          .dispatch(action, { id: this.brandAssetId, form: form })
          .then((data) => {
            // -- MESSAGES
            if (!this.brandAssetId) {
              this.showSnack({
                text: "Brand Assets creato con successo.",
                color: "success",
                timeout: 3500,
              });
            } else {
              this.showSnack({
                text: "Brand Assets salvato con successo.",
                color: "success",
                timeout: 3500,
              });
            }

            this.$router.replace({
              name: "modifica-brand-assets",
              params: { id: data.brand_asset.id },
            });
          })
          .catch((error) => {
            alert(error.message);
          });
      }
    },
    async brandAsset() {
      try {
        let response = await this.$store.dispatch(BRAND_ASSETS_DETAIL, {
          id: this.brandAssetId,
        });
        this.$set(this, "brand_asset", response.brand_asset);
        if (!this.brand_asset.description) {
          this.brand_asset.description = "";
        }
        let logo = (response.brand_asset || {}).logo_brand;
        if (logo) {
          this.$set(this, "logoUrl", logo.url);
        } else {
          this.$set(this, "logoUrl", null);
        }
      } catch (e) {
        alert(e.message);
      }
    },
    addImage(e) {
      e.target.files.forEach(async (element) => {
        try {
          if (!this.brand_asset.logo_brand) {
            this.brand_asset.logo_brand = {
              attachment: null,
              url: null,
            };
          }
          let url = await this.imageToBase64(element);
          this.$set(this.brand_asset.logo_brand, "attachment", element);
          this.$set(this.brand_asset.logo_brand, "url", url);
          this.$set(this, "logoUrl", url);
        } catch (e) {
          // console.log(e);
        }
      });
    },
    imageToBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          reject(error);
        };
      });
    },
    deleteLogo() {
      if (!this.brand_asset.logo_brand.id) {
        this.$set(this.brand_asset.logo_brand, "attachment", null);
        this.$set(this.brand_asset.logo_brand, "url", null);
        this.$set(this, "logoUrl", null);
        return;
      }

      this.$store
        .dispatch(DELETE_ATTACHMENT, this.brand_asset.logo_brand)
        .then(() => {
          this.showSnack({
            text: "Immagine rimossa correttamente",
            color: "success",
            timeout: 1000,
          });
          this.brandAsset();
        })
        .catch((err) => {
          this.showSnack({
            text: err.message,
            color: "danger",
            timeout: 1000,
          });
        });
    },
  },
};
</script>
